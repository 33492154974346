import React from 'react'
import Layout from '../components/Layout'
import Breadcrumb from '../components/Breadcrumb'
import Container from '../components/Container'

const NotFoundPage = () => (
  <Layout>
    <div>
      <Breadcrumb to="404" label="Not Found" />
      <Container className="pt-10 sm:pt-12">
        <h2 className="font-header text-pink text-3xl uppercase text-center">
          Page not found
        </h2>
      </Container>
      <Container className="pt-10 sm:pt-12 pb-24 sm:pb-32">
        <h3 className="text-purple text-2xl font-header my-2">
          We couldn't find anything by that name.
        </h3>
        <p className="font-body">
          We're sorry, but the page you were looking for could not be found.
          Please try again using the site navigation.
        </p>
      </Container>
    </div>
  </Layout>
)

export default NotFoundPage
